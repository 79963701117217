<template>
  <div class="auth">
<!--    Общая страничка для авторизации админа и организаций, при авторизации идет редирект на предыдущую посещенную страницу-->
    <div class="container">
      <div class="auth__success" v-if="checkContentManagerStatus">
        <div class="auth__success-text">Успешная авторизация.</div>
        <div class="auth__success-text">Перейти на
          <router-link to="/">главную</router-link>
        </div>
      </div>
      <div class="auth__failed" v-else>Идет авторизация</div>
    </div>
  </div>
</template>

<script>
// import Keycloak from "keycloak-js";
// import store from "../store";
import keycloackInit from '@/services/keycloakInit'


// const initOptions = {
//   url: "https://auth.ined.ru/auth",
//   realm: "Ined_users",
//   clientId: "ined_client",
//   onLoad: "login-required"
// };
//
// const keycloak = Keycloak(initOptions);
// keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
//   if (!auth) {
//     console.log("AUTH FAILED");
//   } else {
//     store.dispatch('setAuthData', keycloak);
//   }
// })
//   .catch(() => {
//     console.log("AUTHENTICATION FAILED");
//   });

export default {
  name: "AuthPage",
  computed: {
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  },
  mounted() {
    keycloackInit()
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fontSizeXXL;
  font-weight: bold;
  text-align: center;

  &__failed {
    @media screen and (max-width: $mobile-version) {
      font-size: $fontSizeMobileXL;
      line-height: 16px;
    }
  }

  &__success {
    @media screen and (max-width: $mobile-version) {
      font-size: $fontSizeMobileXL;
      line-height: 16px;
    }

    &-text {
      > a {
        color: $primary-color;

        @media screen and (max-width: $mobile-version) {
          font-size: $fontSizeMobileXL;
          line-height: 16px;
        }
      }

      > a:hover {
        text-decoration: underline;
      }
    }
  }

}

</style>